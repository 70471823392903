export default {
  PRODUCTS_LIST: "Liste des produits",
  ADD_PRODUCT: "Ajouter un produit",
  PRODUCT_ADDED: "Produit ajouté",
  EDIT_PRODUCT: "Modifier un produit",
  PRODUCT_UPDATED: "Produit mis à jour",
  DELETE_THIS_PRODUCT: "Supprimer ce produit ?",
  PRODUCT_DELETED: "Produit supprimé",
  SKU: "Sku",
  DELETE_THIS_SUPPLIER_PRODUCT: "Supprimer ce produit?",
  ORDER_FORMAT: "Format de commande",
  STOCKTAKING_FORMAT: "Format de prise d’inventaire",
  ORIGIN: "Provenance",
  PRODUCT_ORIGIN_QUEBEC: "Un Aliment du Québec ou transformé au Québec",
  PRODUCT_ORIGIN_CANADA: "Un aliment du Canada",
  PRODUCT_ORIGIN_OTHER: "Un aliment d’une autre provenance",
  PRODUCT_ORIGIN_UNKNOWN: "Provenance inconnue",
  DELETE_THIS_WAREHOUSE_PRODUCT: "Supprimer ce produit?",
  PRODUCTS_SAVED: "Produits enregistrés",
  ADD_SUPPLIER: "Ajouter un fournisseur",
  STATUS_ACTIVE: "En stock",
  STATUS_INACTIVE: "Inactif",
  STATUS_DELETED: "Supprimé",
  STATUS_BACKORDER: "En rupture de stock",
  SYNC_PRODUCT: "Synchroniser les produits",
  RUN_SYNCHRONIZE_PRODUCTS: "Exécuter la synchronisation",
  RUN_DRY_SYNCHRONIZE_PRODUCTS: "Tester la synchronisation",
  EXCLUDING_TAX: "Produit hors taxes",
  CUSTOM: "Custom",
  CUSTOM_SELLING_PRICE: "Prix de vente",
  CUSTOM_BUYING_PRICE: "Prix coûtant",
  CUSTOM_TAXATION: "Taxes",
  EMPTY_ADD_PRODUCTS:
    "La liste des produits à ajouter est vide ou les produits sélectionnés on été déjà ajoutés",
  CAN_BE_PURCHASED: "Peut être acheté",
  CAN_BE_SOLD: "Peut être vendu",
  SUPPLIER_CODE: "Code fournisseur",
  UNIT_OF_MEASURES_LIST: "Liste des unités de mesure",
  ADD_UNIT_OF_MEASURE: "Ajouter une unité de mesure",
  UNIT_OF_MEASURE_ADDED: "Unité de mesure ajoutés",
  EDIT_UNIT_OF_MEASURE: "Modifier uns unité de mesure",
  UNIT_OF_MEASURE_UPDATED: "Unité de mesure mis à jour",
  DELETE_THIS_UNIT_OF_MEASURE: "Supprimer cette unité de mesure ?",
  UNIT_OF_MEASURE_DELETED: "Unité de mesure supprimée",
  UNIT_RATIO: "Ratio",
  UNIT_TYPE: "Type",
  UNIT_TYPE_REFERENCE_UNIT: "Unité de référence",
  UNIT_TYPE_BIGGER_THAN_REFERENCE: "Plus grand que l'unité de référence",
  UNIT_TYPE_SMALLER_THAN_REFERENCE: "Plus petit que l'unité de référence",
  UNIT_OF_MEASURE_UNIT: "Unité",
  ORDER_UNIT_OF_MEASURE_UNIT: "Unité de commande",
  ORDER_UNIT_OF_MEASURE_UNIT_PRICE: "Prix d'achat",
  ACTIVATE: "Remettre en stock",
  DEACTIVATE: "Desactiver",
  SET_BACKORDER: "Mettre en rupture de stock",
  PRODUCT_ACTIVATED: "Produit en stock",
  PRODUCT_DEACTIVATED: "Produit désactivé",
  PRODUCT_SET_BACKORDER: "Produit mis en rupture de stock",
  SELECT_REPLACEMENTS: "Choisir des produits de remplacements",
  REPLACEMENTS: "Remplacements",
  RETURN_TO_STOCK_DATE: "Date de retour en stock",
  PRODUCT_IN_BACKORDER: "Ce produit est en rupture de stock",
  PRODUCT_BACK_IN_STOCK_ON_X: "Retour en stock prévu le {date}",
};
