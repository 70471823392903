export default {
  APP_NAME: "Cafétéria manager",
  EMAIL: "Courriel",
  PASSWORD: "Mot de passe",
  SIGN_IN: "Connexion",
  FORGOT_PASSWORD: "Mot de passe oublié ?",
  PAGE_NOT_FOUND: "La page demandée n'a pas été touvée",
  RETURN_HOME: "Retour a l'accueil",
  WELCOME: "Bienvenu",
  MY_PROFILE: "Mon profil",
  LOGOUT: "Déconnexion",
  DASHBOARD: "Tableau de bord",
  USERS: "Utilisateurs",
  ROLES: "Rôles",
  ORGANIZATIONS: "Organisations",
  ORGANIZATION: "Organisation",
  PRODUCTS: "Produits",
  PRODUCT: "Produit",
  ALL: "Tout",
  NAME: "Nom",
  ADDRESS: "Adresse",
  PHONE: "Téléphone",
  CREATED_AT: "Crée le",
  SEARCH: "Recherche",
  PER_PAGE: "Par page",
  VIEW: "Voir",
  EDIT: "Modifier",
  DELETE: "Supprimer",
  DISPLAY_FROM_X_TO_X_OF_X_ENTRIES:
    "Affichage de {from} à {to} sur {of} entrées",
  X_LINES_SELECTED: "{count} lignes sélectionnées",
  RETURN_TO_LIST: "Retour à la liste",
  SELECT_IMAGE: "Choisir une image",
  CHANGE_IMAGE: "Changer l'image",
  REMOVE_IMAGE: "Supprimer l'image",
  ROLE: "Rôle",
  FIRSTNAME: "Prénom",
  LASTNAME: "Nom",
  CONFIRM_PASSWORD: "Confirmer le mot de passe",
  RESELLERS: "Revendeurs",
  RESELLER: "Revendeur",
  LOCALE: "Langue",
  LOCALE_FR: "Francais",
  LOCALE_EN: "Anglais",
  GLOBAL: "Global",
  NONE: "Aucun",
  UPDATED_AT: "Mis à jour le",
  PERMISSIONS: "Permissions",
  ALL_ORGANIZATIONS: "Toutes les organisations",
  ALL_RESELLERS: "Tous les revendeurs",
  ALL_ROLES: "Tous les rôles",
  ALL_USERS: "Tous les utilisateurs",
  ALL_PRODUCTS: "Tous les produits",
  EXCERPT: "Description",
  OWNER: "Propriétaire",
  USER: "Utilisateur",
  YES: "Oui",
  NO: "Non",
  OK: "OK",
  PICTURE: "Photo",
  GALLERY: "Galerie",
  PRICE: "Prix",
  RATE: "Taux (%)",
  CODE: "Code",
  DEFAULT_TAXES: "Taxes par défaut",
  INVOICES: "Factures",
  INVOICE: "Facture",
  RECIPIENT: "Destinataire",
  COUNTRY: "Pays",
  COUNTRIES: "Pays",
  STATES: "Régions",
  STATE: "Région",
  CITY: "Ville",
  ZIPCODE: "Code postal",
  NOTE: "Note",
  STATUS: "Statut",
  ALL_STATUSES: "Tous les statuts",
  AMOUNT: "Montant",
  PAIED: "Payé",
  TOTAL: "Total",
  TOTAL_PAIED: "Total payé",
  CREATED_BY: "Créé par",
  DETAILS: "Détails",
  PAYMENTS: "Paiements",
  UNIT_PRICE: "Prix unitaire",
  SUBTOTAL: "Sous-total",
  QUANTITY: "Quantité",
  DISCOUNT: "Réduction",
  REMAINING_PAYMENT_AMOUNT: "Balance",
  DISCOUNT_PERCENT: "Réduction (%)",
  CLOSE: "Fermer",
  CUSTOMER: "Client",
  CUSTOMERS: "Clients",
  LOGS: "Journaux",
  CAUSER: "Auteur",
  DATE: "Date",
  GO_BACK: "Retour",
  PACKAGE: "Forfait",
  PACKAGES: "Forfaits",
  ALL_PACKAGES: "Tous les forfaits",
  SUBSCRIPTION: "Inscription",
  SUBSCRIPTIONS: "Inscriptions",
  TYPE: "Type",
  TAX_TYPE_PERCENTAGE: "Pourcentage",
  TAX_TYPE_AMOUNT: "Montant",
  PAYMENT: "Paiement",
  ALL_INVOICES: "Toutes les factures",
  DISCOUNTS: "Réductions",
  DISCARD_CHANGES_AND_LEAVE_PAGE: "Abandonner les modifications et quitter ?",
  COMPANY_NAME: "Nom de l'entreprise",
  BILLING_ENTITY_TYPE_INDIVIDUAL: "Particulier",
  BILLING_ENTITY_TYPE_COMPANY: "Entreprise",
  CONTACTS: "Contacts",
  CONTACT: "Contact",
  SUPPLIER: "Fournisseur",
  SCHOOL: "Ecole",
  TITLE: "Titre",
  TITLES: "Titres",
  ALL_TITLES: "Tous les titres",
  TITLE_MR: "Monsieur",
  TITLE_MDM: "Madame",
  TAG: "Étiquette",
  TAGS: "Étiquettes",
  ADD_TAG: "Ajouter une étiquette",
  BIRTHDAY: "Date d'anniversaire",
  SUPPLIERS: "Fournisseurs",
  FISCAL_NUMBER: "Numéro fiscale",
  UPDATE: "Modifier",
  UPDATED: "Modifié",
  COLOR: "Couleur",
  LINK_CONTACT: "Lier le contact",
  ADD_CONTACT: "Ajouter un contact",
  ADD: "Ajouter",
  SOURCE: "Source",
  LOCATIONS: "Emplacements",
  LOCATION: "Emplacement",
  ALL_LOCATIONS: "Tous les emplacements",
  FILES: "Fichiers",
  FILE: "Fichier",
  ALL_FILES: "Tous les fichiers",
  FOLDERS: "Dossiers",
  FOLDER: "Dossier",
  ALL_FOLDERS: "Tous les dossiers",
  FILES_EXPLORER: "Explorateur",
  CAFETERIAS: "Cafétérias",
  CAFETERIA: "Cafétéria",
  CUSTOMER_NUMBER: "Numéro client",
  ESTABLISHMENTS: "Établissements",
  ESTABLISHMENT: "Établissement",
  ALL_ESTABLISHMENT: "Tous les établissements",
  SERVICECENTERS: "Centres de service",
  SERVICECENTER: "Centre de service",
  ALL_SERVICECENTER: "Tous les centres de service",
  WAREHOUSE: "Entrepôt",
  WAREHOUSES: "Entrepôts",
  PURCHASES_ORDER: "Commande achat",
  PURCHASES_ORDERS: "Commandes achat",
  ISSUER: "Emetteur",
  PURCHASES_INVOICE: "Facture achat",
  PURCHASES_INVOICES: "Factures achat",
  PURCHASES_DELIVERY: "Livraison achat",
  PURCHASES_DELIVERIES: "Livraisons achat",
  PURCHASES_PAYMENT: "Paiement achat",
  PURCHASES_PAYMENTS: "Paiements achat",
  SALES_ORDER: "Soumission",
  SALES_ORDERS: "Soumissions",
  SALES_INVOICE: "Facture vente",
  SALES_INVOICES: "Factures vente",
  SALES_DELIVERY: "Livraison vente",
  SALES_DELIVERIES: "Livraisons vente",
  SALES_PAYMENT: "Paiement vente",
  RESEND_CODE: "Renvoyez le code",
  ENABLE_2FA:
    "Souhaitez-vous activer la vérification en deux étapes pour sécuriser davantage votre compte ?",
  DISABLE_2FA:
    "Souhaitez-vous désactiver la vérification en deux étapes et revenir à la connexion sans 2FA ?",
  VERIFICATION_CODE: "Code de vérification",
  SALES_PAYMENTS: "Paiements vente",
  BILLING_INFORMATIONS: "Information de facturation",
  BILLING_ADDRESS: "Adresse de facturation",
  RESELLER_PRODUCTS: "Produits revendeur",
  RESELLER_SERVICES: "Services revendeur",
  RESELLER_INVOICES: "Factures revendeur",
  RESELLER_PAYMENTS: "Paiements revendeur",
  SERVICES: "Services",
  SERVICE: "Service",
  RESELLER_PRODUCT: "Produit revendeur",
  RESELLER_SERVICE: "Service revendeur",
  ADD_USER: "Ajouter un utilisateur",
  ALLOWED_USERS: "Utilisateurs autorisés",
  APP: "Applications",
  DELIVERY_ADDRESS: "Adresse de livraison",
  OTHER_PHONES: "Autres téléphones",
  EXTENSION: "Extension",
  ALL_SUPPLIERS: "Tous les fournisseurs",
  PHONE_TYPE_MOBILE: "Mobile",
  PHONE_TYPE_HOME: "Domicile",
  PHONE_TYPE_OFFICE: "Bureau",
  PHONE_TYPE_BUSINESS: "Professionel",
  PHONE_TYPE_FAX: "Fax",
  PHONE_TYPE_PAGER: "Bipeur",
  PHONE_TYPE_OTHER: "Autre",
  SELLING_TAXES: "Taxes de vente",
  BUYING_TAXES: "Taxes d'achat",
  SELLING_PRICE: "Prix de vente",
  BUYING_PRICE: "Prix coûtant",
  DESTINATION_WAREHOUSE: "Entrepôt de destination",
  SOURCE_WAREHOUSE: "Entrepôt source",
  STOCK_MOVEMENT: "Mouvement de stock",
  STOCK_MOVEMENTS: "Mouvements de stock",
  HAS_WAREHOUSE: "Dispose d'un entrepôt",
  SAVE: "Enregistrer",
  EXPECTED_QUANTITY: "Quantité attendue",
  DELIVERED_QUANTITY: "Quantité livrée",
  HAS_FOLDER: "Dispose d’un dossier dans l’explorateur de fichiers",
  IMPORTS: "Imports",
  IMPORT: "Import",
  NEXT_STEP: "Étape suivante",
  PREV_STEP: "Étape précédente",
  CANCEL: "Annuler",
  DOWNLOAD_CSV_FILE: "Télécharger le fichier csv",
  RETURN_TO_LOGIN: "Retour à la connexion",
  RESET_PASSWORD_SENT:
    "Un e-mail contenant un lien de réinitialisation du mot de passe a été envoyé.",
  RESET_PASSWORD_USER_NOT_FOUND:
    "Nous ne trouvons pas d'utilisateur avec cette adresse e-mail.",
  SEND_PASSWORD_RESET_LINK:
    "Envoyer le lien de réinitialisation du mot de passe",
  RESET_PASSWORD: "Réinitialiser le mot de passe",
  PASSWORD_UPDATED: "Mot de passe mis a jour",
  TOKEN_ERROR:
    "Token de changement de mot de passe invalide merci de reessayer",
  EMAIL_ERROR: "Email invalide merci de reessayer",
  SUPPLY_RULES: "Règles d'approvisionnement",
  SEE_MORE: "Voir plus",
  CATEGORY: "Catégorie",
  UPCOMING_PURCHASE: "Achat à venir",
  COPY: "Copier",
  ADD_CAFETERIA: "Ajouter une cafétéria",
  ESTABLISHMENTS_DIRECTORY: "Bottin établissements",
  PROFESSION: "Profession",
  WAREHOUSES_CONFIGURED: "Entrepots associés",
  APPLY_CONFIGURATION: "Appliquer les configurations",
  SYNCHRONIZATIONS: "Synchronisations",
  NEW: "Nouveau",
  UN_SELECT_ALL: "Décocher tout",
  SELECT_ALL: "Cocher tout",
  VALIDATE: "Valider",
  ADD_PRODUCTS: "Ajouter des produits",
  PRINT: "Pdf",
  CATEGORIES: "Catégories",
  TAX: "Taxe",
  TAXES: "Taxes",
  TAX_GROUP: "Groupe de taxes",
  TAX_GROUPS: "Groupes de taxes",
  SEND: "Envoyer",
  MAIL_TO: "A",
  MAIL_FROM: "Expéditeur",
  MAIL_BODY: "Message",
  MAIL_SEND_ME_COPY: "Envoyer moi une copie",
  MAIL_CC: "Cc",
  MAIL_BCC: "Cci",
  MAIL_SUBJECT: "Objet",
  MAIL_TYPE_EMAIL_ENTER: "Tapez un e-mail et appuyez sur Entrée",
  SELECT_CONTACTS: "Entrez plusieurs contacts",
  EMAIL_SENT_SUCCESSFULLY: "Email envoyé avec succès",
  OPTIONS: "Paramètres",
  DEFAULT_TAX_GROUPS: "Groupe de taxes par défaut",
  UNIT_OF_MEASURE: "Unité de mesure",
  UNIT_OF_MEASURES: "Unités de mesure",
  EMAIL_INFOS_SUBJECT_INVOICE: "Facture  {seq_number} de {organization}",
  EMAIL_INFOS_SUBJECT_ORDER: "Soumission  {seq_number} de {organization}",
  EMAIL_INFOS_SUBJECT_PURCHASES_ORDER: "Nouvelle commande {client_id} {establisment_name}",
  EMAIL_INFOS_SUBJECT_PAYMENT: "Paiement  {seq_number} de {organization}",
  EMAIL_INFOS_SUBJECT_DELIVERY: "Livraison  {seq_number} de {organization}",
  START_TYPING_CITY_REGION_COUNTRY:
    "Commencez par saisir la ville, la région ou le pays pour completer l'adresse",
  EMAIL_INFOS_BODY: `
    <strong>Bonjour {username}</strong>,<br>
    Nous vous remercions pour votre confiance. Voici les détails de votre soumission. 
    N’hésitez surtout pas à communiquer avec nous si vous avez des questions.
    <p> Bonne journée ! </p>
    <p> <br> </p>
    <p>{sender_name}</p>
    <p>{email}</p>
    <p>{phone}</p>
    <p>{organization}</p>
  `,
  SALES_ORDER_EMAIL_INFOS_BODY: `
    <strong>Bonjour {username}</strong>,<br>
    Nous vous remercions pour votre confiance. Voici les détails de votre soumission. 
    N’hésitez surtout pas à communiquer avec nous si vous avez des questions.
    <p>Bonne journée !</p>
    {link}
    <p> <br> </p>
    <p>{sender_name}</p>
    <p>{email}</p>
    <p>{phone}</p>
    <p>{organization}</p>
  `,
  PURCHASES_ORDER_EMAIL_INFOS_BODY: `
    <strong>Bonjour {supplier_name}</strong>,<br>
      Voici une nouvelle commande. Veuillez confirmer la réception ainsi que le jour de livraison.
    <p> Merci et bonne journée ! </p>
    <p><br></p>
    <p>{sender_name}</p>
    <p>{email}</p>
    <p>{phone}</p>
    <p>{organization}</p>
  `,
  MANAGER: "Gestionnaire",
  TOTAL_VALUE: "Valeur totale",
  SUMMARY: "Résumé",
  COUNTRY_CODE: "Code pays",
  SELECT_COUNTRY: "Choisir un pays",
  PHONE_NUMBER: "Numéro de téléphone",
  QUEBEC: "Québec",
  RECEIVER_BY: "Receptionné par",
  COUNTRY_CODE: "Code pays",
  SELECT_COUNTRY: "Choisir un pays",
  PHONE_NUMBER: "Numéro de téléphone",
  UNIT_OF_MEASURE_UNIT: "Unité",
  TERMS_CONDITIONS: "Termes et conditions",
  ITEMS_DISCOUNTS: "Réduction produits",
  DESCRIPTION: "Description",
  NUMBER: "Numéro",
  DELETE: "Supprimer",
  EXPORT_EXCEL: "Exporter en excel",
  CORRECT_STOCK: "Corriger l'inventaire",
  HAS_TAXES: "Taxée",
  PAYMENT_TERMS: "Termes de paiement",
  PAYMENT_TERM: "Terme de paiement",
  ADRESSED_TO: "Adressée à",
  EXPIRY_DATE: "Date d'échéance",
  POSITION: "Intitulé du poste",
  WAREHOUSE_QUANTITY: "Quantité en stock",
  AJUSTED_QUANTITY: "Quantité ajustée",
  VALIDATE_SALES_ORDER: "Veuillez cliquer ici pour consulter et accepter la soumission",
  FORMAT: "Format",
};
