<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item
          true
          :link="{
            name: $t('COMMON.DASHBOARD'),
            icon: 'ni ni-shop text-primary',
            path: '/dashboard',
          }"
        >
        </sidebar-item>
        <!--
          <sidebar-item
            v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SUPPLY_RULES)"
            true
            :link="{
              name: $t('COMMON.SUPPLY_RULES'),
              icon: 'fas fa-truck-moving',
              path: '/supply-rule',
            }"
          >
          </sidebar-item>
        -->
        <sidebar-item
          true
          :link="{
            name: $t('COMMON.ESTABLISHMENTS_DIRECTORY'),
            icon: 'ni ni-books text-primary',
            path: '/establishments-directory',
          }"
        >
        </sidebar-item>
        <hr
          class="my-3"
          style="
            border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
            border-style: solid none none;
            border-width: 1px 0px 0px;
            border-image: none 100% / 1 / 0 stretch;
            min-width: 80%;
            overflow: visible;
            box-sizing: content-box;
            height: 0px;
          "
        />

        <lang-switcher />
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";
import { LangSwitcher } from "@/components";
import DashboardNavbar from "../../Layouts/Authenticated/DashboardNavbar.vue";
import ContentFooter from "../../Layouts/Authenticated/ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    LangSwitcher,
  },
  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
